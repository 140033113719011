import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'

export const homeIntro = (delay = 0) => {
  if (viewStorage.current.dataset.taxiView !== 'pageHome' || !domStorage.homeIntro) return

  const logo = domStorage.homeIntro.querySelector('.MainLogo')
  const visuals = domStorage.homeIntro.querySelectorAll('.visual__container')

  // Parameters for visuals
  const duration = 0.45
  const pause = 0.15
  const stagger = duration + pause

  const tl = gsap.timeline({
    delay,
    defaults: { duration: 1, ease: 'power3.inOut' },
    onStart: () => {},
    onComplete: () => {},
  })
  
  // Main logo
  logo && tl.from(logo, { scale: 0, opacity: 0 }, 0)
  logo && tl.to(logo, { yPercent: -300, scale: 0.5, duration: 0.7 })

  if (visuals.length) {
    tl.fromTo(visuals, { scale: 0.5,  opacity: 0 }, { scale: 1, opacity: 1, stagger: {  each: stagger }, duration }, 1.2)
    tl.to(visuals, {  scale: 0.5, opacity: 0, stagger: { each: stagger }, duration }, 1.2 + stagger)
  }

  logo && tl.to(logo, { opacity: 0 }, '-=0.75')
  tl.to(domStorage.homeIntro, { yPercent: -100, duration: 1, ease: 'expo.inOut' }, '-=0.35')

  return tl
}
