import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  const basic_pages =  current.classList.contains('Home') || current.classList.contains('Service') || current.classList.contains('Furniture')

  const pageDOM = {
    page: viewStorage.current,

    // General
    h1: viewStorage.current.querySelector('h1'),
    pageHeaderSubtitle: viewStorage.current.querySelector('.PageHeader > .container > .subtitle'),
    pageHeaderContent: viewStorage.current.querySelector('.PageHeader > .container > .wswyg--content'),
    pageHeaderBtn: viewStorage.current.querySelector('.PageHeader > .container > .Btn'),
    pageHeaderBtnDropdown: viewStorage.current.querySelector('.Btn.--dropdown'),
    pageHeaderBack: viewStorage.current.querySelector('.Back'),
    allSectionsExceptTheFirst: [...viewStorage.current.children].slice(1),

    // Page header with icon
    pageHeaderWithIcon: viewStorage.current.querySelector('.PageHeaderWithIcon'),
    pageHeaderWithIconUser: viewStorage.current.querySelector('.PageHeaderWithIcon .Icon.--user'),
    pageHeaderWithIconFormItems: viewStorage.current.querySelectorAll('.PageHeaderWithIcon form > *'),
  
    // Page header with gallery (Our References, Contact…)
    pageHeaderWithGallery: viewStorage.current.querySelector('.PageHeaderWithGallery'),
    pageHeaderWithGalleryProfilesVisuals: viewStorage.current.querySelectorAll('.PageHeaderWithGallery .hiring-profiles-gallery > *'),
    pageHeaderWithGalleryText: viewStorage.current.querySelector('.PageHeaderWithGallery p'),
    pageHeaderWithGalleryVisuals: viewStorage.current.querySelectorAll('.PageHeaderWithGallery .gallery .visual__container'),
  
    // Page header single
    pageHeaderSingle: viewStorage.current.querySelector('.PageHeaderSingle'),
    pageHeaderSingleDate: viewStorage.current.querySelectorAll('.PageHeaderSingle time'),
    pageHeaderSingleCategories: viewStorage.current.querySelectorAll('.PageHeaderSingle .Categories > *'),
    pageHeaderSharing: viewStorage.current.querySelectorAll('.PageHeaderSingle .Sharing ul > *'),

    // Furnitures
    pageHeaderArchiveFurnitures: viewStorage.current.querySelector('.PageHeaderArchiveFurnitures'),
    pageHeaderArchiveFurnituresWrapperChildren: viewStorage.current.querySelectorAll('.PageHeaderArchiveFurnitures .container > .wrapper > *'),

    // About
    pageHeaderAboutBanner: viewStorage.current.querySelector('.PageHeaderAbout > .visual__container'),
    pageHeaderAboutContent: viewStorage.current.querySelector('.PageHeaderAbout .wswyg--content'),

    // Contact
    pageHeaderContactText: viewStorage.current.querySelector('.PageHeaderContact .wrapper > p'),
    pageHeaderContactGalleryVisuals: viewStorage.current.querySelectorAll('.PageHeaderContact .gallery > .visual__container'),

    // Gutenberg
    gutenbergAllSectionsExceptTheFirst: viewStorage.current.classList.contains('Gutenberg') && [...viewStorage.current.querySelector(':scope > .container.--main').children].slice(1),

    // Pages (legals, …)
    pagesContent: viewStorage.current.classList.contains('Page') && viewStorage.current.querySelector(':scope > .container > .wswyg--content'),
  
    // 404
    notfoundVisual: viewStorage.current.classList.contains('NotFound') && viewStorage.current.querySelector('.visual__container'),
    notfoundText: viewStorage.current.classList.contains('NotFound') && viewStorage.current.querySelector('p'),
    notfoundBtn: viewStorage.current.classList.contains('NotFound') && viewStorage.current.querySelector('.Btn'),
  }

  const tl = gsap.timeline({ 
    delay, 
    defaults: { duration: 1, ease: 'power3.out' },
    onStart: () => {
      ScrollTrigger.refresh()

      // Stop Scroll
      viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

      // Reset scroll with timing for Chrome
      setTimeout(() => window.scrollTo(0, 0), 25)
    },
    onComplete: () => {
      ScrollTrigger.refresh()

      domStorage.body.classList.remove('--animating')
      domStorage.body.classList.remove('--intro')

      // Start Scroll
      viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

      // Header
      gsap.set(domStorage.header, { clearProps: 'all' })

      // General Pages
      if (basic_pages) gsap.set(current, { clearProps: 'y,opacity' })

      // General
      pageDOM.h1 && gsap.set(pageDOM.h1, { clearProps: 'y,opacity' })
      pageDOM.pageHeaderSubtitle && gsap.set(pageDOM.pageHeaderSubtitle, { clearProps: 'y,opacity' })
      pageDOM.pageHeaderContent && gsap.set(pageDOM.pageHeaderContent, { clearProps: 'y,opacity' })
      pageDOM.pageHeaderBtn && gsap.set(pageDOM.pageHeaderBtn, { clearProps: 'y,opacity' })
      pageDOM.pageHeaderBtnDropdown && gsap.set(pageDOM.pageHeaderBtnDropdown, { clearProps: 'y,opacity' })
      pageDOM.pageHeaderBack && gsap.set(pageDOM.pageHeaderBack, { clearProps: 'y,opacity' })
      pageDOM.allSectionsExceptTheFirst.length && gsap.set(pageDOM.allSectionsExceptTheFirst, { clearProps: 'y,opacity' })

      // Page header with gallery
      pageDOM.pageHeaderWithGallery && gsap.set(pageDOM.pageHeaderWithGallery, { clearProps: 'background' })
      pageDOM.pageHeaderWithGalleryProfilesVisuals.length && gsap.set(pageDOM.pageHeaderWithGalleryProfilesVisuals, { clearProps: 'opacity' })
      pageDOM.pageHeaderWithGalleryText && gsap.set(pageDOM.pageHeaderWithGalleryText, { clearProps: 'y,opacity' })
      // pageDOM.pageHeaderWithGalleryVisuals.length && gsap.set(pageDOM.pageHeaderWithGalleryVisuals, { clearProps: 'y,opacity' })

      // Posts
      pageDOM.pageHeaderWithIcon && gsap.set(pageDOM.pageHeaderWithIcon, { clearProps: 'background' })
      pageDOM.pageHeaderWithIconUser && gsap.set(pageDOM.pageHeaderWithIconUser, { clearProps: 'scale,rotate,y,opacity'})
      pageDOM.pageHeaderWithIconFormItems.length && gsap.set(pageDOM.pageHeaderWithIconFormItems, { clearProps: 'opacity' })

      // Article / Job
      pageDOM.pageHeaderSingle && gsap.set(pageDOM.pageHeaderSingle, { clearProps: 'background' })
      pageDOM.pageHeaderSingleDate && pageDOM.pageHeaderSingleCategories.length && gsap.set([pageDOM.pageHeaderSingleDate, pageDOM.pageHeaderSingleCategories], { clearProps: 'opacity' })
      pageDOM.pageHeaderSharing.length && gsap.set(pageDOM.pageHeaderSharing, { clearProps: 'opacity' })

      // Furnitures
      pageDOM.pageHeaderArchiveFurnitures && gsap.set(pageDOM.pageHeaderArchiveFurnitures, { clearProps: 'background' }) 
      pageDOM.pageHeaderArchiveFurnituresWrapperChildren.length && gsap.set(pageDOM.pageHeaderArchiveFurnituresWrapperChildren, { clearProps: 'y,opacity' })

      // About
      pageDOM.pageHeaderAboutBanner && gsap.set(pageDOM.pageHeaderAboutBanner, { clearProps: 'scale,opacity' })
      pageDOM.pageHeaderAboutContent && gsap.set(pageDOM.pageHeaderAboutContent, { clearProps: 'y,opacity' })

      // Contact
      pageDOM.pageHeaderContactText && gsap.set(pageDOM.pageHeaderContactText, { clearProps: 'y,opacity' })
      // pageDOM.pageHeaderContactGalleryVisuals.length && gsap.set(pageDOM.pageHeaderContactGalleryVisuals, { clearProps: 'y, opacity' })

      // Gutenberg
      pageDOM.gutenbergAllSectionsExceptTheFirst.length && gsap.set(pageDOM.gutenbergAllSectionsExceptTheFirst, { clearProps: 'y,opacity' })

      // Pages
      pageDOM.pagesContent && gsap.set(pageDOM.pagesContent, { clearProps: 'y,opacity' })

      // 404
      pageDOM.notfoundVisual && gsap.set(pageDOM.notfoundVisual, { clearProps: 'scale,opacity' })
      pageDOM.notfoundText && gsap.set(pageDOM.notfoundText, { clearProps: 'y,opacity' })
      pageDOM.notfoundBtn && gsap.set(pageDOM.notfoundBtn, { clearProps: 'y,opacity' })
    }
  })

  // Header
  tl.fromTo(domStorage.header, { yPercent: -100 }, { yPercent: 0 }, 1)

  // General Pages
  if (basic_pages) return tl.from(current, { y: 20, opacity: 0 }, 0.15)
  
  // General
  pageDOM.h1 && tl.from(pageDOM.h1, { y: 50, opacity: 0 }, 0)
  pageDOM.pageHeaderSubtitle && tl.from(pageDOM.pageHeaderSubtitle, { y: -20, opacity: 0 }, 0.35)
  pageDOM.pageHeaderContent && tl.from(pageDOM.pageHeaderContent, { y: 20, opacity: 0 }, 0.25)
  pageDOM.pageHeaderBtn && tl.from(pageDOM.pageHeaderBtn, { y: 20, opacity: 0 }, 0.3)
  pageDOM.pageHeaderBtnDropdown && tl.from(pageDOM.pageHeaderBtnDropdown, { y: 20, opacity: 0 }, 0.3)
  pageDOM.pageHeaderBack && tl.from(pageDOM.pageHeaderBack, { y: -20, opacity: 0 }, 1)
  pageDOM.allSectionsExceptTheFirst.length && tl.from(pageDOM.allSectionsExceptTheFirst, { y: 20, opacity: 0 }, '-=1.15')

  // Page header with gallery
  pageDOM.pageHeaderWithGallery && tl.from(pageDOM.pageHeaderWithGallery, { background: '#ffffff' }, 0)
  pageDOM.pageHeaderWithGalleryProfilesVisuals.length && tl.from(pageDOM.pageHeaderWithGalleryProfilesVisuals, { opacity: 0, stagger: 0.25 }, 1)
  pageDOM.pageHeaderWithGalleryText && tl.from(pageDOM.pageHeaderWithGalleryText, { y: 20, opacity: 0 }, 0.25)
  pageDOM.pageHeaderWithGalleryVisuals.length && tl.from(pageDOM.pageHeaderWithGalleryVisuals, { y: 50, opacity: 0, stagger: { each: 0.1, from: 'random' } }, 0)


  // Posts
  pageDOM.pageHeaderWithIcon && tl.from(pageDOM.pageHeaderWithIcon, { background: '#ffffff' }, 0)
  pageDOM.pageHeaderWithIconUser && tl.from(pageDOM.pageHeaderWithIconUser, { scale: 1.5, rotate: 10, y: 50, opacity: 0 }, 0.25)
  pageDOM.pageHeaderWithIconFormItems.length && tl.from(pageDOM.pageHeaderWithIconFormItems, { opacity: 0, stagger: 0.1 }, 0.45)

  // Article / Job
  pageDOM.pageHeaderSingle && tl.from(pageDOM.pageHeaderSingle, { background: '#ffffff' }, 0)
  pageDOM.pageHeaderSingleDate && tl.from(pageDOM.pageHeaderSingleDate, { opacity: 0 }, 0.15)
  pageDOM.pageHeaderSingleCategories && tl.from(pageDOM.pageHeaderSingleCategories, { opacity: 0 }, 0.18)
  pageDOM.pageHeaderSharing.length && tl.from(pageDOM.pageHeaderSharing, { opacity: 0, stagger: 0.05 }, 0.15)

  // Furnitures
  pageDOM.pageHeaderArchiveFurnitures && tl.from(pageDOM.pageHeaderArchiveFurnitures, { background: '#ffffff' }, 0) 
  pageDOM.pageHeaderArchiveFurnituresWrapperChildren.length && tl.from(pageDOM.pageHeaderArchiveFurnituresWrapperChildren, { y: 20, opacity: 0, stagger: 0.15 }, 0.25)

  // About
  pageDOM.pageHeaderAboutBanner && tl.from(pageDOM.pageHeaderAboutBanner, { scale: 1.5, opacity: 0 }, 0.15)
  pageDOM.pageHeaderAboutContent && tl.from(pageDOM.pageHeaderAboutContent, { y: 20, opacity: 0 }, 0.25)

  // Contact
  pageDOM.pageHeaderContactText && tl.from(pageDOM.pageHeaderContactText, { y: 20, opacity: 0 }, 0.25)
  pageDOM.pageHeaderContactGalleryVisuals.length && tl.from(pageDOM.pageHeaderContactGalleryVisuals, { y: 50, opacity: 0, stagger: { each: 0.1, from: 'random' } }, 0.15)

  // Gutenberg
  pageDOM.gutenbergAllSectionsExceptTheFirst.length && tl.from(pageDOM.gutenbergAllSectionsExceptTheFirst, { y: 20, opacity: 0 }, '-=1.15')

  // Pages
  pageDOM.pagesContent && tl.from(pageDOM.pagesContent, { y: 20, opacity: 0 }, 0.25)

  // 404
  pageDOM.notfoundVisual && tl.from(pageDOM.notfoundVisual, { scale: 1.2, opacity: 0 }, 0)
  pageDOM.notfoundText && tl.from(pageDOM.notfoundText, { y: 20, opacity: 0 }, 0.25)
  pageDOM.notfoundBtn && tl.from(pageDOM.notfoundBtn, { y: 20, opacity: 0 }, 0.35)

  return tl
}
