import { domStorage, viewStorage } from '../_globals'
import Form from './form'

/**
 * @param {HTMLElement} - Name of the section
 */
export default class SpontaneousApplicationModal {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.button = this.DOM.container.querySelector('.wrapper .inner .Btn')
    this.DOM.modal = this.DOM.container.querySelector('.SpontaneousApplicationModal')
    this.DOM.form = this.DOM.container.querySelector('.SpontaneousApplicationModal .Form')
    this.DOM.close = this.DOM.container.querySelector('.SpontaneousApplicationModal .Close')

    // Settings
    this.className = '--show-spontaneous-application-modal'
    this.formModule = null

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)

    this.DOM.button.addEventListener('click', this.open)
    this.DOM.close.addEventListener('click', this.close)
  }

  open(e) {
    e && e.preventDefault()

    // Display the modal
    domStorage.body.classList.add(this.className)

    // Hide the header
    domStorage.header.classList.add('--hide')

    // Stop the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // ScrollTo the top of the modal
    this.DOM.modal.scrollTo(0, 0)

    // Init form module
    // this.formModule = new Form(this.DOM.form)
  }

  close(e) {
    e && e.preventDefault()

    // Hide the modal with body className
    domStorage.body.classList.remove(this.className)

    // Show the header
    setTimeout(() => domStorage.header.classList.remove('--hide'), 250)

    // Restart the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Reset the form (buttons incrementation)
    this.formModule && this.DOM.form && this.DOM.form.reset()

    // Destroy form module
    this.formModule && this.formModule.destroy()
  }

  destroy() {
    this.DOM.button.removeEventListener('click', this.open)
    this.DOM.close.removeEventListener('click', this.close)
    this.formModule && this.formModule.destroy()
  }
}
