import { domStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(Observer, ScrollToPlugin)

export default class ScrollTop {
  constructor() {
    if (!domStorage.scrollTop) return

    this.DOM = { el: domStorage.scrollTop }

    // Observers
    this.setObservers()

    domStorage.scrollTop.addEventListener('click', () => {
      gsap.to(window, { scrollTo: 0, ease: 'expo.out', duration: 1.25 })
    })
  }

  setObservers() {
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: (self) => {
        if (self.scrollY() <= 20) !el.classList.contains('--hide') && el.classList.add('--hide')
      },
      onDown: (self) => { 
        self.scrollY() > 20 && el.classList.remove('--hide')
      },
    })
  }
}
