import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import PageHeaderParallax from '../animations/page-header-parallax'
import References from '../modules/references'

export default class ArchiveReferences extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderWithGallery'),
      referencesMap: viewStorage.current.querySelector('.ReferencesMap'),
      referencesMapForm: viewStorage.current.querySelector('.ReferencesMap .MapForm'),
      referencesMapDropdownButton: viewStorage.current.querySelector('.ReferencesMap .MapForm .Btn.--dropdown')
    }

    this.init()
  }

  init() {
    const {
      pageHeader = null,
      referencesMap = null,
      referencesMapDropdownButton = null
    } = this.DOM

    if (pageHeader) this.pageHeaderParallaxModule = new PageHeaderParallax(pageHeader)
    if (referencesMap) this.referencesMapModule = new References(referencesMap)
      
    // Events
    if (referencesMapDropdownButton) {
      this.toggleDropdown = this.toggleDropdown.bind(this)
      referencesMapDropdownButton.addEventListener('click', this.toggleDropdown)
    }
  }

  toggleDropdown(e) {
    e && e.preventDefault()

    this.DOM.referencesMapForm.classList.toggle('--show-dropdown')
  }

  onLeaveCompleted() {
    this.pageHeaderParallaxModule && this.pageHeaderParallaxModule.destroy()
    this.referencesMapModule && this.referencesMapModule.destroy()
  }
}
