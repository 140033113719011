import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class viewInView {

  constructor(container = viewStorage.current) {

    this.DOM = {
      inviewItems: container.querySelectorAll('*[data-inview]')
    }

    if (this.DOM.inviewItems.length === 0) return

    viewStorage.hasInView = true

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init viewInView')

    this.isHorizontal = false

    // ScrollTrigger.defaults({
    //   markers: isDEVMODE
    // })

    this.DOM.inviewItems.forEach((el) => {

      const inviewOnce = el.dataset.inview === 'once'
      const inviewStart = el.dataset.inviewStart ? el.dataset.inviewStart : 'top bottom-=10%'
      const inviewEnd = el.dataset.inviewEnd ? el.dataset.inviewEnd : 'bottom top'

      el.scrollTrigger = ScrollTrigger.create({
        trigger: el,
        toggleClass: '--visible',
        once: inviewOnce,
        start: inviewStart,
        end: inviewEnd,
        onToggle: ({direction, isActive}) => {
          if (direction > 0) {
            el.classList.add('from--btm')
            el.classList.remove('from--top')
          } else {
            el.classList.add('from--top')
            el.classList.remove('from--btm')
          }
        }
      })
    })
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy viewInView')

    this.DOM.inviewItems.forEach((el) => {
      if (viewStorage.current.contains(el)) {
        el.scrollTrigger.kill(false)
      }
    })

    viewStorage.hasInView = false

  }
}
