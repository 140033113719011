import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import PageHeaderHome from '../modules/page-header-home'
import Numbers from '../modules/numbers'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import ReferencesStaticMap from '../modules/references-static-map'

export default class PageHome extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderHome'),
      relatedFurnituresTypesSwiper: viewStorage.current.querySelector('.RelatedFurnituresTypes .swiper.--mobile'),
      numbers: viewStorage.current.querySelector('.Numbers'),
      references: viewStorage.current.querySelector('.References'),
      clientsSliderSwiper: viewStorage.current.querySelector('.ClientsSlider .swiper'),
      relatedArticlesMobileSwiper: viewStorage.current.querySelector('.RelatedArticles .swiper.--mobile'),
    }

    this.init()
  }

  init() {
    const {
      pageHeader = null,
      relatedFurnituresTypesSwiper = null,
      numbers = null,
      references = null,
      clientsSliderSwiper = null,
      relatedArticlesMobileSwiper = null
    } = this.DOM

    if (pageHeader) this.pageHeaderModule = new PageHeaderHome(pageHeader)
    if (relatedFurnituresTypesSwiper) this.relatedFurnituresTypesSwiperModule = new MobileSwiper(relatedFurnituresTypesSwiper)
    if (numbers) this.numbersModule = new Numbers(numbers)
    if (references) this.referencesStaticMapModule = new ReferencesStaticMap(references)
    if (clientsSliderSwiper) this.clientsSwiperModule = new WknSwiper(clientsSliderSwiper, { slidesPerView: 'auto' })
    if (relatedArticlesMobileSwiper) this.relatedArticlesMobileSwiperModule = new MobileSwiper(relatedArticlesMobileSwiper)
  }

  onLeaveCompleted() {
    this.pageHeaderModule && this.pageHeaderModule.destroy()
    this.relatedFurnituresTypesSwiperModule && this.relatedFurnituresTypesSwiperModule.destroy()
    this.numbersModule && this.numbersModule.destroy()
    this.referencesStaticMapModule && this.referencesStaticMapModule.destroy()
    this.clientsSwiperModule && this.clientsSwiperModule.destroy()
    this.relatedArticlesMobileSwiperModule && this.relatedArticlesMobileSwiperModule.destroy()
  }
}
