import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import Accordions from '../modules/accordions'

export default class PageFAQ extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderWithIcon.--faq'),
      pageHeaderDropdownButton: viewStorage.current.querySelector('.PageHeaderWithIcon.--faq .container > .Btn.--dropdown')
    }

    // Events
    if (this.DOM.pageHeader && this.DOM.pageHeaderDropdownButton) {
      this.toggleDropdown = this.toggleDropdown.bind(this)
      this.DOM.pageHeaderDropdownButton.addEventListener('click', this.toggleDropdown)
    }

    // Modules
    this.accordionsModule = new Accordions(viewStorage.current)
  }

  toggleDropdown(e) {
    e && e.preventDefault()

    this.DOM.pageHeader.classList.toggle('--show-dropdown')
  }

  onLeaveCompleted() {
    if (this.DOM.pageHeader && this.DOM.pageHeaderDropdownButton) this.DOM.pageHeaderDropdownButton.removeEventListener('click', this.toggleDropdown)
    this.accordionsModule && this.accordionsModule.destroy()
  }
}
