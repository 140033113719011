import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import Numbers from '../modules/numbers'
import ReferencesStaticMap from '../modules/references-static-map'
import NationalPresenceMap from '../modules/national-presence-map'

export default class PageGutenberg extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      relatedFurnituresTypesSwiper: viewStorage.current.querySelector('.RelatedFurnituresTypes .swiper.--mobile'),
      numbersSections: viewStorage.current.querySelectorAll('.Numbers'),
      references: viewStorage.current.querySelector('.References'),
      nationalPresence: viewStorage.current.querySelector('.NationalPresence'),
      relatedServicesSwiper: viewStorage.current.querySelector('.RelatedServices .swiper.--mobile'),
      relatedArticlesMobileSwiper: viewStorage.current.querySelector('.RelatedArticles .swiper.--mobile'),
    }

    this.numbersModules = []

    this.init()
  }

  init() {
    const {
      numbersSections = null,
      references = null,
      relatedFurnituresTypesSwiper = null,
      nationalPresence = null,
      relatedServicesSwiper = null,
      relatedArticlesMobileSwiper = null
    } = this.DOM

    if (numbersSections && numbersSections.length) numbersSections.forEach(section => this.numbersModules.push(new Numbers(section)))
    if (references) this.referencesStaticMapModule = new ReferencesStaticMap(references)
    if (relatedFurnituresTypesSwiper) this.relatedFurnituresTypesSwiperModule = new MobileSwiper(relatedFurnituresTypesSwiper)
    if (nationalPresence) this.nationalPresenceModule = new NationalPresenceMap(nationalPresence)
    if (relatedServicesSwiper) this.relatedServicesSwiperModule = new MobileSwiper(relatedServicesSwiper)
    if (relatedArticlesMobileSwiper) this.relatedArticlesMobileSwiperModule = new MobileSwiper(relatedArticlesMobileSwiper)
  }

  onLeaveCompleted() {
    this.numbersModules && this.numbersModules.length && this.numbersModules.forEach(module => module.destroy())
    this.referencesStaticMapModule && this.referencesStaticMapModule.destroy()
    this.relatedFurnituresTypesSwiperModule && this.relatedFurnituresTypesSwiperModule.destroy()
    this.nationalPresenceModule && this.nationalPresenceModule.destroy()
    this.relatedServicesSwiperModule && this.relatedServicesSwiperModule.destroy()
    this.relatedArticlesMobileSwiperModule && this.relatedArticlesMobileSwiperModule.destroy()
  }
}
