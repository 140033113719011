import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import NationalPresenceMap from '../modules/national-presence-map'
import Engagements from '../modules/engagements'
// import OurStory from '../modules/our-story'
import OurStoryIllustration from '../animations/our-story-illustration'
import FixedNavAnchors from '../modules/fixed-navigation'

export default class PageAbout extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      specialitiesSwiper: viewStorage.current.querySelector('.Specialities .swiper.--mobile'),
      valuesSwiper: viewStorage.current.querySelector('.Values .swiper'),
      nationalPresence: viewStorage.current.querySelector('.NationalPresence'),
      ourStory: viewStorage.current.querySelector('.OurStory'),
      values: viewStorage.current.querySelector('.Values'),
      engagementsSection: viewStorage.current.querySelector('.Engagements'),
      relatedFurnituresSwiper: viewStorage.current.querySelector('.RelatedFurnitures .swiper.--mobile'),
      specialities: viewStorage.current.querySelector('.Specialities'),
      engagements: viewStorage.current.querySelector('.Engagements'),
      fixedNavigation: viewStorage.current.querySelector('.fixed-navigation')
    }

    this.init()
  }

  init() {
    const {
      specialitiesSwiper = null,
      valuesSwiper = null,
      nationalPresence = null,
      ourStory = null,
      engagementsSection = null,
      relatedFurnituresSwiper = null,
      specialities = null,
      values = null,
      engagements = null,
      fixedNavigation = null,
    } = this.DOM

    if (specialitiesSwiper) this.specialitiesSwiperModule = new MobileSwiper(specialitiesSwiper)
    if (valuesSwiper) this.valuesSwiperModule = new WknSwiper(valuesSwiper, { slidesPerView: 'auto', spaceBetween: 25 })
    if (nationalPresence) this.nationalPresenceModule = new NationalPresenceMap(nationalPresence)
    // if (ourStory) this.ourStoryModule = new OurStory(ourStory)
    if (ourStory) this.ourStoryModule = new OurStoryIllustration(ourStory)
    if (engagementsSection) this.engagementsModule = new Engagements(engagementsSection)
    if (relatedFurnituresSwiper) this.relatedFurnituresSwiperModule = new MobileSwiper(relatedFurnituresSwiper)
    if (fixedNavigation) this.fixedNavigationModule = new FixedNavAnchors(fixedNavigation, [specialities, nationalPresence, ourStory, values, engagements])
  }

  onLeaveCompleted() {
    this.specialitiesSwiperModule && this.specialitiesSwiperModule.destroy()
    this.valuesSwiperModule && this.valuesSwiperModule.destroy()
    this.nationalPresenceModule && this.nationalPresenceModule.destroy()
    this.ourStoryModule && this.ourStoryModule.destroy()
    this.engagementsModule && this.engagementsModule.destroy()
    this.relatedFurnituresSwiperModule && this.relatedFurnituresSwiperModule.destroy()
    this.fixedNavigationModule && this.fixedNavigationModule.destroy()
  }
}
