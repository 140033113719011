import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import PageHeaderFurniture from '../modules/page-header-furniture'

export default class singleFurniture extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderFurniture'),
      clientsSliderSwiper: viewStorage.current.querySelector('.ClientsSlider .swiper'),
      relatedFurnituresSwiper: viewStorage.current.querySelector('.RelatedFurnitures .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const {
      pageHeader = null,
      clientsSliderSwiper = null,
      relatedFurnituresSwiper = null
    } = this.DOM

    if (pageHeader) this.pageHeaderModule = new PageHeaderFurniture(pageHeader)
    if (clientsSliderSwiper) this.clientsSwiperModule = new WknSwiper(clientsSliderSwiper, { slidesPerView: 'auto' })
    if (relatedFurnituresSwiper) this.relatedFurnituresSwiperModule = new MobileSwiper(relatedFurnituresSwiper)
  }

  onLeaveCompleted() {
    this.pageHeaderModule && this.pageHeaderModule.destroy()
    this.clientsSwiperModule && this.clientsSwiperModule.destroy()
    this.relatedFurnituresSwiperModule && this.relatedFurnituresSwiperModule.destroy()
  }
}
