import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)
import Search from './search'
import NavSubmenus from './nav-submenus'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.nav = this.DOM.el.querySelector('.Nav')

    this.setEvents()
  }

  setEvents() {
    // Events
    this.toggleMobile = this.toggleMobile.bind(this)
    this.resize = this.resize.bind(this)

    // Global functions
    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()
    globalStorage.search = new Search()
    globalStorage.navSubmenus = new NavSubmenus()

    // Mobile
    this.DOM.toggle && this.DOM.toggle.addEventListener('click', this.toggleMobile)

    // Overlay
    domStorage.overlay && domStorage.overlay.addEventListener('click', () => globalStorage.menuOpen && this.close())

    // Observers
    this.setObservers()

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.resize)
  }

  toggleMobile(e) {
    e && e.preventDefault()

    globalStorage.menuOpen ? this.close() : this.open()
  }

  open() {
    globalStorage.menuOpen = true
    
    domStorage.body.classList.add('--show-menu')

    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()
  }
  
  close() {
    globalStorage.menuOpen = false
    
    domStorage.body.classList.remove('--show-menu')

    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()
  }

  setObservers() {
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: (self) => {
        el.classList.remove('--hide')

        if (self.scrollY() <= 20) el.classList.remove('--scroll')
      },
      onDown: (self) => { 
        !el.classList.contains('--hide') && el.classList.add('--hide')

        self.scrollY() > 20 && !el.classList.contains('scroll') && el.classList.add('--scroll')
      },
    })
  }

  resize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
