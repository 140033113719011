import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import WknSwiper from './wkn-swiper'

/**
 * @param {HTMLElement} - Name of the section
 */
export default class Numbers {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.swiper = this.DOM.container.querySelector('.swiper')
    this.DOM.numbers = this.DOM.container.querySelectorAll('.Number')

    if (!this.DOM.swiper) return

    // Settings
    this.tweens = []

    // Swiper
    this.module = new WknSwiper(this.DOM.swiper, {
      slidesPerView: 1,
      spaceBetween: 20,
      centerInsufficientSlides: true,
      loop: true, 
      autoplay: { delay: 2500, disableOnInteraction: false },
      breakpoints: {
        500: { slidesPerView: 2 },
        650: { slidesPerView: 3 },
        768: { slidesPerView: 4 }
      },
    })

    this.module.swiper.autoplay.pause()

    // ScrollTrigger to add autoplay
    this.st = new ScrollTrigger.create({
      trigger: this.DOM.container,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: () => { setTimeout(() => this.module.swiper.autoplay.start(), 2500) },
      onLeave: () => { this.module.swiper.autoplay.pause() },
      onEnterBack: () => { setTimeout(() => this.module.swiper.autoplay.start(), 2500) },
      onLeaveBack: () => { this.module.swiper.autoplay.pause() },
    })

    // Animations for each number
    this.DOM.numbers.forEach(number => {
      const span = number.querySelector('span[data-number]')

      const tween = gsap.fromTo(span, { 
        textContent: '0' 
      }, { 
        textContent: span.dataset.number, 
        duration: 2.5, 
        ease: 'power3.out', 
        snap: { textContent: 1 },
        scrollTrigger: {
          trigger: number,
          start: 'top center'
        }
      })

      this.tweens.push(tween)
    })
  }

  destroy() {
    this.module && this.module.destroy()
    this.st && this.st.kill()
    this.tweens && this.tweens.length && this.tweens.forEach(tween => tween.kill())
  }
}
