import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {HTMLElement} - Name of the section
 */
export default class PageHeaderParallax {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.visuals = this.DOM.container.querySelectorAll('.gallery .visual__container')

    if (!this.DOM.visuals && !this.DOM.visuals.length) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setTimeline()
  }

  setTimeline() {
    const { container, visuals } = this.DOM

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap.timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: container,
            start: 'top top',
            end: 'bottom top',
            scrub: true
          }
        })
        .fromTo(visuals, {
          yPercent: index => {
            switch (index) {
              case 0: return -20
              case 1: return -5
              case 2: return 35
              default: return 25
            }
          },
          scale: index => {
            switch (index) {
              case 0: return 1.15
              case 1: return 1.15
              case 2: return 0.95
              default: return 1.05
            }
          }
        }, {
          yPercent: index => {
            switch (index) {
              case 0: return 50
              case 1: return 120
              case 2: return -50
              default: return -25
            }
          },
          scale: index => {
            switch (index) {
              case 0: return 1
              case 1: return 0.95
              case 2: return 1.05
              default: return 0.95
            }
          }
        }, 0)
      } else {
        this.destroy()
      }
    })
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
