/**
 * @param {HTMLElement} - Name of the section
 */
export default class NationalPresenceMap {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.markers = [...this.DOM.container.querySelectorAll('.NationalPresenceMap .markers .marker')]
    this.DOM.markersButtons = [...this.DOM.container.querySelectorAll('.NationalPresenceMap .markers button')]
    this.DOM.popupMobile = this.DOM.container.querySelector('.popup-mobile')

    if (!this.DOM.markersButtons && !this.DOM.markersButtons.length) return

    // Params
    this.currentPopup = null
    
    // Events
    this.openPopup = this.openPopup.bind(this)    
    this.closePopup = this.closePopup.bind(this)

    this.DOM.markersButtons.forEach(button => button.addEventListener('click', this.openPopup))
  }

  openPopup(e) {
    e && e.preventDefault()

    // Update currentPopup variable
    this.currentPopup = e.currentTarget.parentNode

    // Toggles
    this.DOM.markers.filter(m => m === this.currentPopup ? m.classList.toggle('--active') : m.classList.remove('--active'))

    // Display HTML in the popup mobile
    this.DOM.popupMobile.innerHTML = this.currentPopup.querySelector('.popup').innerHTML

    // Adding the event on document (click outside)
    setTimeout(() => document.addEventListener('click', this.closePopup), 50)
  }

  closePopup(e) {
    e && e.stopPropagation()

    if (this.currentPopup.contains(e.target)) return

    // Close the currentPopup
    this.currentPopup.classList.remove('--active')

    // Remove HTML in the popup mobile
    this.DOM.popupMobile.innerHTML = ''

    // Remove event on document
    document.removeEventListener('click', this.closePopup)
  }

  destroy() {
    this.DOM.markersButtons && this.DOM.markersButtons.length && this.DOM.markersButtons.forEach(button => button.removeEventListener('click', this.openPopup))
  }
}
