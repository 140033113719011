import { Renderer } from '@unseenco/taxi'
import { domStorage, viewStorage } from '../_globals'
import Form from '../modules/form'

export default class ArchiveArticles extends Renderer {

  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderWithIcon.--archive-articles'),
      pageHeaderDropdownButton: viewStorage.current.querySelector('.PageHeaderWithIcon.--archive-articles .container > .Btn.--dropdown'),

      radios: viewStorage.current.querySelectorAll('input[name="questions_categories"]'),
      loadMoreButton: viewStorage.current.querySelector('.load-more-articles-button'),
      displayedCount: viewStorage.current.querySelector('.current-number'),
      totalCount: viewStorage.current.querySelector('.total-number'),
      progressBar: viewStorage.current.querySelector('.progress-bar'),
      articlesContainer: viewStorage.current.querySelector('.articles-container'),
    }

    this.init()

    // Events
    if (this.DOM.pageHeader && this.DOM.pageHeaderDropdownButton) {
      this.toggleDropdown = this.toggleDropdown.bind(this)
      this.DOM.pageHeaderDropdownButton.addEventListener('click', this.toggleDropdown)
    }
  }

  init() {
    const { radios = null, loadMoreButton = null } = this.DOM
    radios && radios.forEach((radio) => {
      radio.addEventListener('change', this.loadArticles.bind(this))
    })

    loadMoreButton && loadMoreButton.addEventListener('click', this.loadArticles.bind(this))
  }

  loadArticles(e) {
    const ajaxUrl = e.target.dataset.ajaxUrl
    // check if e.target is a radio button or a button
    const isRadio = e.target.type === 'radio'
    if (!ajaxUrl) return
    const { loadMoreButton = null, displayedCount = null, progressBar = null, totalCount = null } = this.DOM
    domStorage.body.classList.add('--loading')
    fetch(ajaxUrl)
      .then((response) => response.json())
      .then((data) => {
        const { html, pagination } = data
        const { displayedPosts, progress, displayLoadMore, url, total } = pagination
        displayedCount.innerHTML = displayedPosts
        totalCount.innerHTML = total
        progressBar.style.width = progress + '%'
        if (isRadio) {
          this.DOM.articlesContainer.innerHTML = html
        } else {
          this.DOM.articlesContainer.insertAdjacentHTML('beforeend', html)
        }
        if (displayLoadMore) {
          loadMoreButton.style.display = 'inline-flex'
          loadMoreButton.dataset.ajaxUrl = url
        } else {
          loadMoreButton.style.display = 'none'
        }

        domStorage.body.classList.remove('--loading')
      })
  }

  toggleDropdown(e) {
    e && e.preventDefault()

    this.DOM.pageHeader.classList.toggle('--show-dropdown')
  }

  onLeaveCompleted() {
    const { radios = null, loadMoreButton = null } = this.DOM
    radios && radios.forEach((radio) => {
      radio.removeEventListener('change', this.loadArticles.bind(this))
    })
    loadMoreButton && loadMoreButton.removeEventListener('click', this.loadArticles.bind(this))

    if (this.DOM.pageHeader && this.DOM.pageHeaderDropdownButton) this.DOM.pageHeaderDropdownButton.removeEventListener('click', this.toggleDropdown)
  }
}
