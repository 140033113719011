import { viewStorage } from '../_globals'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class Illustration {
  constructor() {
    // DOM
    this.DOM = {
      scrollContainer: viewStorage.current.querySelector('.ScrollContainer'),
      illustration: viewStorage.current.querySelector('.Illustration'),
      svg: viewStorage.current.querySelector('.Illustration svg'),
      mainTimelineLine: viewStorage.current.querySelector('.Illustration svg .Main_timeline'),
      items: viewStorage.current.querySelectorAll('.Illustration svg .item'),
      circles: viewStorage.current.querySelectorAll('.Illustration svg .Circle'),
      locationPoints: viewStorage.current.querySelectorAll('.Illustration svg .Locations path'),
      moreItems: viewStorage.current.querySelector('.Illustration svg .More_items')
    }

    if (!this.DOM.scrollContainer) return

    // Pin
    this.scrollTween = gsap.fromTo(this.DOM.illustration, {
      xPercent: 5,
    }, {
      xPercent: -90,
      ease: 'none',
      scrollTrigger: {
        trigger: this.DOM.scrollContainer,
        pin: this.DOM.scrollContainer,
        pinSpacer: false,
        // pinSpacing: false,
        scrub: 0.1,
        start: 'center center',
        // end: 'bottom center',
        end: '+=5000',
        // markers: true
      }
    })

    // Main timeline line
    this.timelineLineTween = gsap.fromTo(this.DOM.mainTimelineLine, {
      drawSVG: '0%'
    }, {
      drawSVG: '100%',
      ease: 'none',
      scrollTrigger: {
        trigger: this.DOM.mainTimelineLine,
        containerAnimation: this.scrollTween,
        start: 'top 50%',
        end: 'bottom 50%',
        scrub: 0.1,
        id: 'Main_timeline_line',
        // markers: true
      }
    })

    // All items
    this.DOM.items.forEach((item, index) => {
      item.tween = gsap.fromTo(item, {
        opacity: 0,
        y: (index, el, els) => {
          if (el.classList.contains('from-top')) {
            return -20
          } else if (el.classList.contains('from-bottom')) {
            return 20
          } else {
            return 0
          }
        }
      }, {
        opacity: 1,
        y: 0,
        ease: 'expo.out',
        duration: 1,
        scrollTrigger: {
          trigger: item,
          containerAnimation: this.scrollTween,
          id: `Item_${index}`,
          // scrub: true,
          // markers: true,
          start: 'top center',
          end: 'bottom bottom',

          // On enter, on leave, on enter back, on leave back
          toggleActions: 'restart resume restart reset'
        }
      })
    })

    // All circles
    this.DOM.circles.forEach((circle, index) => {
      gsap.set(circle, { transformOrigin: '50% 50%' })

      circle.tween = gsap.fromTo(circle, {
        opacity: 0,
        scale: 0,
      }, {
        opacity: 1,
        scale: 1,
        ease: 'expo.out',
        duration: 1,
        scrollTrigger: {
          trigger: circle,
          containerAnimation: this.scrollTween,
          id: `Circle_${index}`,
          // scrub: true,
          // markers: true,
          start: 'top center',
          end: 'bottom bottom',
          
          // On enter, on leave, on enter back, on leave back
          toggleActions: 'restart resume restart reset'
        }
      })
    })

    // Location points
    this.DOM.locationPoints.forEach((point, index) => {
      point.tween = gsap.fromTo(point, {
        opacity: 0,
      }, {
        opacity: 1,
        ease: 'expo.out',
        duration: 1,
        scrollTrigger: {
          trigger: point,
          containerAnimation: this.scrollTween,
          id: `Point_${index}`,
          // scrub: true,
          // markers: true,
          start: 'top center',
          end: 'bottom bottom',
          
          // On enter, on leave, on enter back, on leave back
          toggleActions: 'restart resume restart reset'
        }
      })
    })
    
    // More items
    this.moreItemsTween = gsap.fromTo(this.DOM.moreItems, {
      opacity: 0,
    }, {
      opacity: 1,
      ease: 'expo.out',
      duration: 1,
      scrollTrigger: {
        trigger: this.DOM.moreItems,
        containerAnimation: this.scrollTween,
        id: 'More_items',
        // scrub: true,
        // markers: true,
        start: 'top center',
        end: 'bottom bottom',
        
        // On enter, on leave, on enter back, on leave back
        toggleActions: 'restart resume restart reset'
      }
    })
    
  }

  /**
   * Destroy
   */
  destroy() {
    if (!this.DOM.scrollContainer) return
    

    // Kill all tweens
    this.scrollTween.kill()
    this.timelineLineTween.kill()
    this.DOM.items.forEach((item, index) => item.tween.kill())
    this.DOM.circles.forEach((circle, index) => circle.tween.kill())
    this.DOM.locationPoints.forEach((point, index) => point.tween.kill())
    this.moreItemsTween.kill()
  }
}