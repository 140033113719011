/**
 * @param {HTMLElement} - Name of the section
 */
export default class Engagements{
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.navButtons = [...this.DOM.container.querySelectorAll('.sections nav ul li button')]
    this.DOM.sections = [...this.DOM.container.querySelectorAll('.sections .EngagementSection')]

    if (!this.DOM.sections && !this.DOM.sections.length) return

    // Params 
    this.className = '--active'

    // Events
    this.showSection = this.showSection.bind(this)

    this.DOM.navButtons.forEach(button => button.addEventListener('click', this.showSection))
  }

  showSection(e) {
    e && e.preventDefault()

    const { navButtons, sections } = this.DOM

    const currentButtonIndex = navButtons.indexOf(e.currentTarget)
    const relatedSection = sections[currentButtonIndex]

    // Update active items
    navButtons.filter(button => button === e.currentTarget ? button.classList.add(this.className) : button.classList.remove(this.className))
    sections.filter(section => section === relatedSection ? section.classList.add(this.className) : section.classList.remove(this.className))
  }

  destroy() {
    this.DOM.navButtons && this.DOM.navButtons.length && this.DOM.navButtons.forEach(button => button.removeEventListener('click', this.showSection))
  }
}
