/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageHome from './pages/page-home'
import PageContact from './pages/page-contact'
import PageFAQ from './pages/page-faq'
import PageAbout from './pages/page-about'
import PageSearch from './pages/page-search'
import PageGutenberg from './pages/page-gutenberg'
import ArchiveArticles from './pages/archive-articles'
import ArchiveFurnitures from './pages/archive-furnitures'
import ArchiveJobs from './pages/archive-jobs'
import ArchiveServices from './pages/archive-services'
import ArchiveReferences from './pages/archive-references'
import SingleJob from './pages/single-job'
import SingleFurniture from './pages/single-furniture'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    // Pages
    default: Page,
    pageHome: PageHome,
    pageFAQ: PageFAQ,
    pageContact: PageContact,
    pageAbout: PageAbout,
    pageSearch: PageSearch,
    pageGutenberg: PageGutenberg,
    
    // Archives
    archiveArticles: ArchiveArticles,
    archiveFurnitures: ArchiveFurnitures,
    archiveJobs: ArchiveJobs,
    archiveServices: ArchiveServices,
    archiveReferences: ArchiveReferences,
    
    // Singles
    singleJob: SingleJob,
    singleFurniture: SingleFurniture,
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
