import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { homeIntro } from './home-intro'
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const tl = gsap.timeline({
    delay: 0.2,
    onStart: () => {
      // Update body classNames
      domStorage.body.classList.remove('--preloading')
      domStorage.body.classList.remove('--loading')

      // Reset scroll with timing for Chrome
      setTimeout(() => window.scrollTo(0, 0), 25)
    },
    onComplete: () => {
      // Update firstLoad variable
      globalStorage.firstLoad = false

      // Remove home intro from the DOM
      domStorage.homeIntro && domStorage.homeIntro.remove()
    }
  })

  if (globalStorage.firstLoad && viewStorage.current.dataset.taxiView === 'pageHome') {
    tl.add(homeIntro(0), 0)
    tl.add(pageIntro(0), '-=0.1')
  } else {
    domStorage.homeIntro && domStorage.homeIntro.remove()
    tl.add(pageIntro(), 0)
  }
  
  return tl
}
