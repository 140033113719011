import { domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { pageIntro } from '../animations/page-intro'
import { FormsManager } from '@wokine-agency/forms'

// For contact estimation links, we need to create a variable to adapt the subject in Contact page
let contactSubjectValue = null
// For single furniture, we need to stock the furniture title for the message field in contact form (textarea)
let furnitureTitle = null

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    const tl = gsap.timeline({
      defaults: { duration: 1, ease: 'power3.out' },
      onComplete: () => {
        domStorage.body.classList.remove('--animating')

        // Clear styles
        gsap.set(domStorage.footer, { clearProps: 'opacity' })

        done()
      }
    })
    .to(domStorage.footer, { opacity: 1 }, 0)
    .add(pageIntro(0), 0)

    return tl
  }

  onLeave({ from, trigger, done }) {
    const tl = gsap.timeline({
      defaults: { duration: 1, ease: 'power3.out' },
      onStart: () => {
        domStorage.body.classList.add('--animating')
      },
      onComplete: () => {
        done()
      }
    })
    .to([from, domStorage.footer], { opacity: 0 }, 0)
    .to(domStorage.header, { yPercent: -100 }, 0)

    return tl
  }
}
