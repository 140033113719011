import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {HTMLElement} - Name of the section
 */
export default class PageHeaderHome {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.keywords = [...this.DOM.container.querySelectorAll('.keywords strong')]
    this.DOM.galleryItemsGrid = [...this.DOM.container.querySelectorAll('.gallery > .item-grid')]
    this.DOM.galleryVisuals = [...this.DOM.container.querySelectorAll('.gallery .visual__contaienr')]

    this.setKeywordsUp()
    this.setGallery()
  }

  setKeywordsUp() {
    const { keywords = null } = this.DOM

    if (!keywords && !keywords.length) return

    const numberOfKeywords = keywords.length
    const duration = 1.25
    const pause = 3
    const stagger = duration + pause
    const repeatDelay = stagger * (numberOfKeywords - 1) + pause

    this.keywordsTl = gsap
      .timeline({ paused: true, defaults: { ease: 'power3.inOut' } })
      .fromTo(keywords, { 
        yPercent: 100, 
        scale: 0.5, 
        opacity: 0 
      }, { 
        yPercent: 0, 
        scale: 1,
         opacity: 1, 
         stagger: { 
          each: stagger, 
          repeat: -1, 
          repeatDelay 
        }, duration 
      })
      .to(keywords, { 
        yPercent: -100, 
        scale: 0.5, 
        opacity: 0, 
        stagger: { 
          each: stagger, 
          repeat: -1, 
          repeatDelay 
        }, duration 
      }, stagger)

    this.keywordsTl.play()
  }

  setGallery() {
    if (!this.DOM.galleryItemsGrid && !this.DOM.galleryItemsGrid.length) return

    gsap.set(this.DOM.galleryVisuals, { opacity: 0, scale: 0 })

    this.galleryTl = gsap.timeline({ delay: 1, repeat: -1, defaults: { duration: 1.25 } })

    this.DOM.galleryItemsGrid.forEach(item => {
      const visuals = item.querySelectorAll('.visual__container')

      this.galleryTl.fromTo(visuals, { opacity: 0.5, scale: 0 }, { opacity: 1, scale: 1, stagger: { from: 'random', each: 0.15 }, ease: 'elastic.out(1,1)' })
      this.galleryTl.to(visuals, { scale: 0.5, opacity: 0, stagger: { from: 'random', each: 0.15 }, ease: 'elastic.in(1,1)' }, '+=1')
    })
  }

  destroy() {
    this.keywordsTl && this.keywordsTl.kill()
    this.galleryTl && this.galleryTl.kill()
  }
}