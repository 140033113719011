import { domStorage, viewStorage } from '../_globals'
import WknSwiper from './wkn-swiper'
import Form from './form'

/**
 * @param {HTMLElement} - Name of the section
 */
export default class PageHeaderFurniture {
  constructor(container) {
    if (!container) return

    // DOM
    this.DOM = { container }
    this.DOM.pageHeaderGallerySwiperMain = container.querySelector('.PageHeaderFurniture .swiper.--main')
    this.DOM.pageHeaderGallerySwiperThumbs = container.querySelector('.PageHeaderFurniture .swiper.--thumbs')

    this.DOM.modal = this.DOM.container.querySelector('.modal')
    this.DOM.form = this.DOM.container.querySelector('.modal .Form')
    this.DOM.openModalButton = this.DOM.container.querySelector('nav.links ul li button')
    this.DOM.closeModalButton = this.DOM.container.querySelector('.modal button.Close')

    // Settings
    this.className = '--show-furniture-modal'
    this.formModule = null

    // Modal Events
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.DOM.openModalButton && this.DOM.openModalButton.addEventListener('click', this.openModal)
    this.DOM.closeModalButton && this.DOM.closeModalButton.addEventListener('click', this.closeModal)

    // Swipers
    this.DOM.pageHeaderGallerySwiperMain && this.setSwipers()
  }

  setSwipers() {
    const { pageHeaderGallerySwiperMain, pageHeaderGallerySwiperThumbs } = this.DOM

    this.pageHeaderGallerySwiperThumbsModule = new WknSwiper(pageHeaderGallerySwiperThumbs, {
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesProgress: true,
      spaceBetween: 10,
      slideToClickedSlide: true
    })

    this.pageHeaderGallerySwiperMainModule = new WknSwiper(pageHeaderGallerySwiperMain, {
      thumbs: { swiper: this.pageHeaderGallerySwiperThumbsModule.swiper }
    })

  }

  openModal(e) {
    e && e.preventDefault()

    // Display the modal
    domStorage.body.classList.add(this.className)

    // Hide the header
    domStorage.header.classList.add('--hide')

    // Stop the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // Init form module
    // this.formModule = new Form(this.DOM.form)
  }

  closeModal(e) {
    e && e.preventDefault()

    // Hide the modal with body className
    domStorage.body.classList.remove(this.className)

    // Show the header
    setTimeout(() => domStorage.header.classList.remove('--hide'), 250)

    // Restart the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Reset the form (buttons incrementation)
    this.formModule && this.DOM.form && this.DOM.form.reset()

    // Destroy form module
    this.formModule && this.formModule.destroy()
  }

  destroy() {
    // Modal Events
    this.DOM.openModalButton && this.DOM.openModalButton.removeEventListener('click', this.openModal)
    this.DOM.closeModalButton && this.DOM.closeModalButton.removeEventListener('click', this.closeModal)

    // Swipers
    this.pageHeaderGallerySwiperThumbsModule && this.pageHeaderGallerySwiperThumbsModule.destroy()
    this.pageHeaderGallerySwiperMainModule && this.pageHeaderGallerySwiperMainModule.destroy()

    // Form
    this.formModule && this.formModule.destroy()
  }
}
