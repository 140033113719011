import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'

export default class ArchiveServices extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      relatedFurnituresTypesSwiper: viewStorage.current.querySelector('.RelatedFurnituresTypes .swiper.--mobile'),
    }

    this.init()
  }

  init() {
    const {
      relatedFurnituresTypesSwiper = null,
    } = this.DOM

    if (relatedFurnituresTypesSwiper) this.relatedFurnituresTypesSwiperModule = new MobileSwiper(relatedFurnituresTypesSwiper)

  }

  onLeaveCompleted() {
    this.relatedFurnituresTypesSwiperModule && this.relatedFurnituresTypesSwiperModule.destroy()
  }
}
