import { domStorage, viewStorage } from '../_globals'
import Form from './form'

/**
 * @param {HTMLElement} - Name of the section
 */
export default class PageHeaderArchiveFurnitures {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.buttonDropdownMobile = this.DOM.container.querySelector('.Btn.--dropdown')
    this.DOM.openFiltersButton = this.DOM.container.querySelector('.Btn.open-filters')
    this.DOM.modal = this.DOM.container.querySelector('.FiltersFurnituresModal')
    this.DOM.modalInner = this.DOM.modal.querySelector(':scope > .inner')

    if (!this.DOM.openFiltersButton && !this.DOM.modal) return

    this.DOM.form = this.DOM.modal.querySelector('form')
    this.DOM.resetButton = this.DOM.modal.querySelector('.bottom .Btn.reset')
    this.DOM.sendButton = this.DOM.modal.querySelector('.bottom .Btn.send')
    this.DOM.close = this.DOM.modal.querySelector('.close-modal')
    this.DOM.inputs = this.DOM.modal.querySelectorAll('input')

    // Update Navbar links
    this.DOM.navLinks = [...this.DOM.container.querySelectorAll('.PageHeaderArchiveFurnitures .head nav ul li .Link')]
    this.DOM.navLinks && this.DOM.navLinks.length && this.DOM.navLinks.filter(link =>  window.location.href.includes(link.dataset.slug) ? link.classList.add('--active') : link.classList.remove('--active'))

    // Update Checkbox links
    this.DOM.checkboxLinks = [...this.DOM.container.querySelectorAll('.checkboxes-grid .Checkbox')]
    this.DOM.checkboxLinks && this.DOM.checkboxLinks.length && this.DOM.checkboxLinks.filter(checkbox => window.location.href.includes(checkbox.dataset.slug) ? checkbox.classList.add('--active') : checkbox.classList.remove('--active'))

    this.DOM.form && this.DOM.form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      if (checkbox.checked) this.initialTotal++
    })

    // Settings
    this.initialTotal = 0
    this.formModule = null
    this.className = '--show-furnitures-modal'
    this.total = this.initialTotal
    this.setDefaultSubmitButtonTotal(this.total)
    
    // Events
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.updateTotal = this.updateTotal.bind(this)
    this.closeOutside = this.closeOutside.bind(this)

    this.toggleDropdownMobile = this.toggleDropdownMobile.bind(this)

    this.DOM.openFiltersButton.addEventListener('click', this.openModal)
    this.DOM.resetButton.addEventListener('click', this.resetForm)
    this.DOM.close.addEventListener('click', this.closeModal)
    this.DOM.inputs.forEach(input => input.addEventListener('change', this.updateTotal))

    // this.DOM.sendButton.addEventListener('click', this.sendForm)

    this.DOM.buttonDropdownMobile.addEventListener('click', this.toggleDropdownMobile)
  }

  /**
   * Modal
   */
  openModal(e) {
    e && e.preventDefault()

    // Display the modal with body className
    domStorage.body.classList.add(this.className)

    // Hide the header
    domStorage.header.classList.add('--hide')

    // Stop the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // ScrollTo the top of the modal
    this.DOM.modal.scrollTo(0, 0)

    // Init the form
    // this.formModule = new Form(this.DOM.form)

    // Close outside the modal inner
    setTimeout(() => document.addEventListener('click', this.closeOutside), 250)
  }

  closeModal(e) {
    e && e.preventDefault()

    // Hide the modal with body className
    domStorage.body.classList.remove(this.className)

    // Show the header
    setTimeout(() => domStorage.header.classList.remove('--hide'), 250)

    // Restart the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Reset the form (buttons incrementation)
    this.DOM.form && this.DOM.form.reset()
    this.total = this.initialTotal

    // Destroy the form
    this.formModule && this.formModule.destroy()

    // Remove event on document
    document.removeEventListener('click', this.closeOutside)
  }

  /**
   * Form
   */
  resetForm(e) {
    e && e.preventDefault()

    // Reset all the checkboxes
    this.DOM.form &&  this.DOM.form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false)

    // Update the total variable
    this.total = 0

    // Reset the button text content
    this.DOM.sendButton.querySelector(':scope > span').textContent = 'Appliquer'
  }

  sendForm(e) {
    e && e.preventDefault()

    // Send datas from the form
    alert('Send datas!')
  }

  updateTotal(e) {
    // Update button text content with checkboxes total
    e.currentTarget.checked ? this.total++ : this.total--

    const text = this.total >= 1 ? `Appliquer (${this.total})` : 'Appliquer'

    this.DOM.sendButton.querySelector(':scope > span').textContent = text
  }

  closeOutside(e) {
    e && e.stopPropagation()

    if (this.DOM.modalInner.contains(e.target)) return

    this.closeModal()
  }

  setDefaultSubmitButtonTotal (count) {
    if (count > 0) {
      this.DOM.sendButton.querySelector(':scope > span').textContent = `Appliquer (${count})`
    }
  }

  /**
   * Dropdown (mobile)
   */
  toggleDropdownMobile(e) {
    e && e.preventDefault()

    this.DOM.container.classList.toggle('--show-dropdown')
  }

  /**
   * Destroy
   */
  destroy() {
    this.total = 0

    // Modal Events
    this.DOM.openFiltersButton.removeEventListener('click', this.openModal)
    this.DOM.resetButton.removeEventListener('click', this.resetForm)

    this.DOM.buttonDropdownMobile.removeEventListener('click', this.toggleDropdownMobile)

    // Form
    this.formModule && this.formModule.destroy()
  }
}
