import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import PageHeaderParallax from '../animations/page-header-parallax'
import FixedNavAnchors from '../modules/fixed-navigation'
import SpontaneousApplicationModal from '../modules/spontaneous-application-modal'

export default class ArchiveJobs extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderWithGallery'),
      workAtUrbaneo: viewStorage.current.querySelector('#travailler-chez-urbaneo'),
      allJobOffers: viewStorage.current.querySelector('#nos-offres'),
      spontaneousApplicationSection: viewStorage.current.querySelector('.SpontaneousApplication'),
      fixedNavigation: viewStorage.current.querySelector('.fixed-navigation'),
      jobsContainer: viewStorage.current.querySelector('.JobsContainer'),
      currentCount: viewStorage.current.querySelector('.current-number'),
      progressBar: viewStorage.current.querySelector('.progress-bar'),
      loadMoreButton: viewStorage.current.querySelector('.load-more-jobs-button'),
    }

    this.init()
  }

  init() {
    const {
      loadMoreButton = null,
      pageHeader = null,
      fixedNavigation = null,
      workAtUrbaneo = null,
      allJobOffers = null,
      spontaneousApplicationSection = null
    } = this.DOM

    if (pageHeader) this.pageHeaderParallaxModule = new PageHeaderParallax(pageHeader)
    if (fixedNavigation) this.fixedNavigationModule = new FixedNavAnchors(fixedNavigation, [ workAtUrbaneo, allJobOffers ])
    if (spontaneousApplicationSection) this.spontaneousApplicationModule = new SpontaneousApplicationModal(spontaneousApplicationSection)
    if (loadMoreButton) loadMoreButton.addEventListener('click', this.loadMoreJobs.bind(this))
  }

  onLeaveCompleted() {
    this.pageHeaderParallaxModule && this.pageHeaderParallaxModule.destroy()
    this.spontaneousApplicationModule && this.spontaneousApplicationModule.destroy()
    this.fixedNavigationModule && this.fixedNavigationModule.destroy()
    const { loadMoreButton } = this.DOM
    if (loadMoreButton) loadMoreButton.removeEventListener('click', () => this.loadMoreJobs.bind(this))
  }

  loadMoreJobs () {
    const { jobsContainer, currentCount, progressBar, loadMoreButton } = this.DOM
    const { ajaxUrl} = loadMoreButton.dataset
    loadMoreButton.disabled = true
    loadMoreButton.classList.add('is-loading')
    fetch(ajaxUrl)
      .then(response => response.json())
      .then(data => {
        jobsContainer.innerHTML += data.html
        currentCount.innerHTML = data.pagination.displayedPosts
        progressBar.style.width = data.pagination.progress + '%'
        if (!data.pagination.displayLoadMore) loadMoreButton.style.display = 'none'
      })
      .catch(error => console.error(error))
      .finally(() => {
        loadMoreButton.disabled = false
        loadMoreButton.classList.remove('is-loading')
      })
  }
}
