import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import PageHeaderArchiveFurnitures from '../modules/page-header-archive-furnitures'
import ReferencesStaticMap from '../modules/references-static-map'

export default class ArchiveFurnitures extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderArchiveFurnitures'),
      references: viewStorage.current.querySelector('.References'),
      clientsSliderSwiper: viewStorage.current.querySelector('.ClientsSlider .swiper'),
      loadMoreButton: viewStorage.current.querySelector('.load-more-furnitures'),
      currentNumber: viewStorage.current.querySelector('.current-number'),
      totalNumber: viewStorage.current.querySelector('.total-number'),
      progressBar: viewStorage.current.querySelector('.progress-bar'),
      referencesContainer: viewStorage.current.querySelector('.references-container'),
    }

    this.init()
  }

  init() {
    const {
      pageHeader = null,
      references = null,
      clientsSliderSwiper = null,
      loadMoreButton = null,
    } = this.DOM

    if (pageHeader) this.pageHeaderArchiveFurnituresModule = new PageHeaderArchiveFurnitures(pageHeader)
    if (references) this.referencesStaticMapModule = new ReferencesStaticMap(references)
    if (loadMoreButton) loadMoreButton.addEventListener('click', this.loadMoreFurnitures.bind(this))
    if (clientsSliderSwiper) this.clientsSwiperModule = new WknSwiper(clientsSliderSwiper, { slidesPerView: 'auto' })
  }

  loadMoreFurnitures() {
    const { loadMoreButton, referencesContainer, currentNumber, totalNumber, progressBar } = this.DOM

    loadMoreButton.disabled = true

    const url = this.DOM.loadMoreButton.dataset.ajaxUrl

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const { html, pagination } = data
        const { displayedPosts, total, url, progress, displayLoadMore } = pagination
        currentNumber.innerHTML = displayedPosts
        totalNumber.innerHTML = total
        progressBar.style.width = `${progress}%`
        loadMoreButton.dataset.ajaxUrl = url
        referencesContainer.insertAdjacentHTML('beforeend', html)
        loadMoreButton.disabled = false
        if (displayLoadMore === false) {
          loadMoreButton.style.display = 'none'
        } else {
          loadMoreButton.style.display = 'inline-flex'
        }
      })
      .catch(error => console.log(error))
  }

  onLeaveCompleted() {
    this.pageHeaderArchiveFurnituresModule && this.pageHeaderArchiveFurnituresModule.destroy()
    this.referencesStaticMapModule && this.referencesStaticMapModule.destroy()
    this.clientsSwiperModule && this.clientsSwiperModule.destroy()
  }
}
