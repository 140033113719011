import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {HTMLElement} - Name of the section
 */
export default class ReferencesMap {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.map = this.DOM.container.querySelector('.map')
    this.DOM.mapCircleImages = this.DOM.container.querySelectorAll('.map .circle-image')

    if (!this.DOM.mapCircleImages && !this.DOM.mapCircleImages.length) return

    this.setTimeline()
  }

  setTimeline() {
    const { container, mapCircleImages } = this.DOM

    gsap.set(mapCircleImages, { transformOrigin: '50% 50%' })

    const numberOfCircles = mapCircleImages.length
    const duration = 0.75
    const pause = 0.35
    const stagger = duration + pause
    const repeatDelay = stagger * (numberOfCircles - 1) + pause

    this.tl = gsap.timeline({ paused: true, defaults: { ease: 'power3.inOut' } })
                  .fromTo(mapCircleImages, { scale: 0 }, { scale: 1, stagger: { each: stagger, repeat: -1, repeatDelay }, duration })
                  .to(mapCircleImages, { scale: 0, stagger: { each: stagger, repeat: -1, repeatDelay }, duration }, stagger)

  
    // Play the animation only when the section is visible
    this.st = new ScrollTrigger.create({
      trigger: container,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: () => this.tl.play(),
      onLeave: () => this.tl.pause(),
      onEnterBack: () => this.tl.play(),
      onLeaveBack: () => this.tl.pause()
    })
  }

  destroy() {
    this.tl && this.tl.kill()
    this.st && this.st.kill()
  }
}