import { viewStorage, domStorage } from '../_globals'
import { buildAjaxUrl } from '../utils'
import { fetch } from 'whatwg-fetch'
import mapboxgl from 'mapbox-gl'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

/**
 * @param {HTMLElement} - Name of the section
 */
export default class References {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.map = this.DOM.container.querySelector('#map')
    this.DOM.referencesItems = [...this.DOM.container.querySelectorAll('.ReferenceItem')]
    this.DOM.referencesNoResult = this.DOM.container.querySelector('aside.items > p')
    this.DOM.loadMoreButton = this.DOM.container.querySelector('.Pagination > .Btn')
    this.DOM.postsContainer = this.DOM.container.querySelector('.items')

    if (!this.DOM.map) return

    // Modal
    this.setModal()

    // MapBox Settings
    mapboxgl.accessToken = 'pk.eyJ1Ijoid29raW5lIiwiYSI6ImNqbzdhdXhuZTBmeXAzcnBnZnE4bWVzMWMifQ.gRXN2yYTM-SOpyZiWk9Frg'

    // Settings
    this.markers = []
    this.className = '--active'

    // Events
    this.displayPopup = this.displayPopup.bind(this)
    this.loadMore = this.loadMore.bind(this)

    // this.DOM.loadMoreButton && this.DOM.loadMoreButton.addEventListener('click', this.loadMore)

    this.setMap()
    this.setReferencesItems()

    // FILTERS (initialization in setMap() -> fetch)
    this.DOM.checkboxesPrimaryAll = this.DOM.container.querySelector('.checkboxes.--primary input#all-types')
    this.DOM.checkboxesPrimary = this.DOM.container.querySelectorAll('.checkboxes.--primary input')
    this.DOM.checkboxesModal = this.DOM.container.querySelectorAll('.FiltersReferencesModal .Tab .Checkbox input')
    
    this.updateFilteredItems = this.updateFilteredItems.bind(this)
  }

  /**
   * Modal
   */

  setModal() {
    this.DOM.openFiltersButton = this.DOM.container.querySelector('.Btn.open-filters')
    this.DOM.modal = this.DOM.container.querySelector('.FiltersReferencesModal')
    this.DOM.modalInner = this.DOM.modal.querySelector(':scope > .inner')

    if (!this.DOM.openFiltersButton && !this.DOM.modal) return

    this.DOM.form = this.DOM.modal.querySelector('form')
    this.DOM.resetButton = this.DOM.modal.querySelector('.bottom .Btn.reset')
    this.DOM.sendButton = this.DOM.modal.querySelector('.bottom .Btn.send')
    this.DOM.close = this.DOM.modal.querySelector('.close-modal')
    this.DOM.inputs = this.DOM.modal.querySelectorAll('.Tabs input')

    this.DOM.form && this.DOM.form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      if (checkbox.checked) this.initialTotal++
    })

    // Settings
    this.initialTotal = 0
    this.formModule = null
    this.bodyClassName = '--show-references-modal'
    this.total = this.initialTotal
    this.setDefaultSubmitButtonTotal(this.total)

    // Events
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.updateTotal = this.updateTotal.bind(this)
    this.closeOutside = this.closeOutside.bind(this)
    this.updateTab = this.updateTab.bind(this)

    this.DOM.openFiltersButton.addEventListener('click', this.openModal)
    this.DOM.resetButton.addEventListener('click', this.resetForm)
    this.DOM.close.addEventListener('click', this.closeModal)
    this.DOM.inputs.forEach(input => input.addEventListener('change', this.updateTotal))

    // Tabs
    this.DOM.navTabInputs = [...this.DOM.modal.querySelectorAll('.NavTabInput input')]
    this.DOM.tabs = [...this.DOM.modal.querySelectorAll('.Tabs .Tab')]

    this.DOM.navTabInputs.forEach(input => input.addEventListener('change', this.updateTab))

    this.DOM.sendButton.addEventListener('click', this.sendForm)
  }

  openModal(e) {
    e && e.preventDefault()

    // Display the modal with body className
    domStorage.body.classList.add(this.bodyClassName)

    // Hide the header
    domStorage.header.classList.add('--hide')

    // Stop the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // ScrollTo the top of the modal
    this.DOM.modal.scrollTo(0, 0)

    // Init the form
    // this.formModule = new Form(this.DOM.form)

    // Close outside the modal inner
    setTimeout(() => document.addEventListener('click', this.closeOutside), 250)
  }

  closeModal(e) {
    e && e.preventDefault()

    // Hide the modal with body className
    domStorage.body.classList.remove(this.bodyClassName)

    // Show the header
    setTimeout(() => domStorage.header.classList.remove('--hide'), 250)

    // Restart the smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Reset the form (buttons incrementation)
    // this.DOM.form && this.DOM.form.reset()
    // this.total = this.initialTotal

    // Destroy the form
    // this.formModule && this.formModule.destroy()

    // Remove event on document
    document.removeEventListener('click', this.closeOutside)
  }

  resetForm(e) {
    e && e.preventDefault()

    // Reset all the checkboxes
    this.DOM.form && this.DOM.form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false)

    // Update the total variable
    this.total = 0

    // Reset the button text content
    this.DOM.sendButton.querySelector(':scope > span').textContent = 'Appliquer'
  }

  sendForm(e) {
    e && e.preventDefault()

    // Update filter
    this.updateFilteredSpecificItems()

    // Close modal
    this.closeModal()
  }

  updateTotal(e) {
    // Update button text content with checkboxes total
    e.currentTarget.checked ? this.total++ : this.total--

    const text = this.total >= 1 ? `Appliquer (${this.total})` : 'Appliquer'

    this.DOM.sendButton.querySelector(':scope > span').textContent = text
  }

  closeOutside(e) {
    e && e.stopPropagation()

    if (this.DOM.modalInner.contains(e.target)) return

    this.closeModal()
  }

  setDefaultSubmitButtonTotal (count) {
    if (count > 0) {
      this.DOM.sendButton.querySelector(':scope > span').textContent = `Appliquer (${count})`
    }
  }

  updateTab(e) {
    const { value } = e.currentTarget

    this.DOM.tabs.forEach(tab => tab.dataset.value === value ? tab.classList.add('--active') : tab.classList.remove('--active'))
  }

  /**
   * Filters
   */

  // Main function to filter items
  updateFilteredItems(e) {
    if (!e) return
    
    const { id } = e.currentTarget

    if (id === 'all-types') {
      this.updateFilteredAllItems(e)
    } else {
      this.updateFilteredSpecificItems()
    }
  }

  // Only for the "All" filter checkbox input
  updateFilteredAllItems(e) {
    if (e.currentTarget.checked) {
      this.DOM.checkboxesPrimary.forEach(checkbox => checkbox !== e.currentTarget && (checkbox.checked = false))

      this.DOM.referencesNoResult.setAttribute('aria-hidden', 'true')
    
      this.DOM.referencesItems.forEach(item => {
        item.style.display = 'flex'

        this.markers.forEach(marker => {
          marker.p.remove()
          marker.el.style.display = 'block'
        })
      })
    }
  }

  // Filter for specific items 
  updateFilteredSpecificItems() {
    this.DOM.checkboxesPrimaryAll.checked = false

    // We need to get the value of the primary checkbox inputs AND the modal checkbox inputs
    const selectedTypes = [
      ...Array.from(this.DOM.checkboxesPrimary).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value),
      ...Array.from(this.DOM.checkboxesModal).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
    ]

    let itemsVisibleCount = 0

    this.DOM.referencesItems.forEach(item => {
      const itemTypes = Array.from(item.classList)

      const matches = selectedTypes.some(type => itemTypes.includes(`--${type}`))

      if (matches) {
        itemsVisibleCount++
      }
      
      item.style.display = matches ? 'flex' : 'none'
  
      this.markers.forEach(marker => {
        if (parseInt(item.getAttribute('data-id')) === marker.id) {
          marker.p.remove()
          marker.el.style.display = matches ? 'block' : 'none'
        }
      })
    })

    if (itemsVisibleCount) {
      this.DOM.referencesNoResult.setAttribute('aria-hidden', 'true')
    } else {
      this.DOM.referencesNoResult.removeAttribute('aria-hidden')
    }
  }

  /**
   * Map
   */
  setTemplate(marker) {
    const related_client_link = `
      <a href="${marker.related_client_url}" class="visual__container" target="_blank" rel="noreferrer noopener">
        <span class="--sr-only">${marker.related_client_title}</span>

        <div class="visual">
          <img src="${marker.related_client_logo.url}" alt="${marker.related_client_title}" />
        </div>
      </a>
    `

    const related_client_preview = `
      <div class="visual__container">
        <span class="--sr-only">${marker.related_client_title}</span>

        <div class="visual">
          <img src="${marker.related_client_logo.url}" alt="${marker.related_client_title}" />
        </div>
      </div>
    `

    // Popup Template for the map
    return `
      <div class="ReferenceMarker">
        <div class="visual__container --br-5">
          <div class="visual">
            ${(marker.thumbnail && marker.thumbnail.guid) ? `<img src="${marker.thumbnail.guid}" />` : '' }
          </div>
        </div>

        <div class="wrapper">
          ${marker.location_title ? `<address>${marker.location_title}</address>` : ''}

          <span class="--c-blue">${marker.title}</span>

          ${marker.description ? `<div class="wswyg--content --c-grey --small">${marker.description}</div>` : ''}

          ${marker.related_client_title ?
            marker.related_client_url ?
              related_client_link :
              related_client_preview
          : '' }
        </div>
      </div>
    `
  }

  setMap() {
    // MapBox
    this.map = new mapboxgl.Map({
      container: this.DOM.map,
      style: 'mapbox://styles/wokine/cl7ei7nh8005014jvft859shf',
      center: [3.1043032, 50.6767018],
      interactive: true,
      // zoom: 1.85,
      // minZoom: 8.5,
      // maxZoom: 12,
      // zoom: 10,

      minZoom: 5.5,
      maxZoom: 12,
      zoom: 5.5,

      projection: 'equalEarth',
    })

    // this.map.scrollZoom.disable()

    this.map.addControl(new mapboxgl.NavigationControl())

    // Fetching from the BrandController function
    fetch(buildAjaxUrl('fetch_references', { isAjax: true }))
      .then(res => res.json())
      .then(res => {
        this.setMarkers(res.posts)
        this.DOM.checkboxesPrimary.forEach(checkbox => checkbox.addEventListener('change', this.updateFilteredItems))
      })
      .catch(error => console.log(error))
  }

  setMarkers(data) {
    if (!data && !data.length) return

    const markerClassName = '--open'

    data.forEach(marker => {
      if (!marker.map_coordinates) return

      // Creating the marker
      const el = document.createElement('div')
      el.className = 'marker'
      marker.el = el

      // Creating the template
      marker.p = new mapboxgl.Popup({ closeButton: true }).setHTML(this.setTemplate(marker))

      // Marker Events
      // ——— Open
      marker.p.on('open', popup => {
        const { target } = popup

        // Update className from the marker
        target._marker.getElement().classList.add(markerClassName)

        this.map.flyTo({
          center: [marker.map_coordinates.lng, marker.map_coordinates.lat],
          offset: [0, 0],
          zoom: 10,
          duration: 1000,
          essential: true,
          pitch: 0,
          bearing: 0
        })

        // Update items from the sidebar
        this.DOM.referencesItems.filter(item => parseInt(item.getAttribute('data-id')) === marker.id ? item.classList.add(this.className) : item.classList.remove(this.className))
      })
      // ——— Close
      marker.p.on('close', popup => {
        const { target } = popup

        // Update className from the marker
        target._marker.getElement().classList.remove(markerClassName)
        target.removeClassName(markerClassName)

        // this.map.flyTo({
        //   center: [1.85, 46.85],
        //   zoom: 5.5,
        //   duration: 1000
        // })

        // Update items from the sidebar
        this.DOM.referencesItems.filter(item => item.classList.contains(this.className) && item.classList.remove(this.className))
      })

      // Marker coordinates
      marker.m = new mapboxgl.Marker(el)
                  .setLngLat([marker.map_coordinates.lng, marker.map_coordinates.lat])
                  .setPopup(marker.p)
                  .addTo(this.map)

      // Push into the markers global array (to destroy it later)
      this.markers.push(marker)
    })
  }

  /**
   * Events for the sidebar buttons
   */
  setReferencesItems() {
    this.DOM.referencesItems = [...this.DOM.container.querySelectorAll('.ReferenceItem')]
    const { referencesItems } = this.DOM
    if (!referencesItems && !referencesItems.length) return

    referencesItems.forEach(item => {
      // skip if already have an event
      // if (item.classList.contains('hasEvent')) return
      item.addEventListener('click', this.displayPopup)
      // item.classList.add('hasEvent')
    })
  }

  displayPopup(e) {
    e && e.preventDefault()

    if (!this.markers && !this.markers.length) return

    const currentItem = e.currentTarget
    const itemID = parseInt(currentItem.getAttribute('data-id'))

    this.DOM.referencesItems.filter(item => {
      if (item === currentItem) {
        // Adapt button style
        setTimeout(() => item.classList.add(this.className), 50)

        // flyTo + open popup
        this.markers.forEach(marker => {
          if (marker.id === itemID) {
            marker.m._popup.addTo(this.map)

            this.map.flyTo({
              center: [marker.map_coordinates.lng, marker.map_coordinates.lat],
              offset: [0, 0],
              zoom: 10,
              duration: 1000,
              essential: true,
              pitch: 0,
              bearing: 0
            })

            // this.map.flyTo({
            //   center: [(Math.random() - 0.5) * 360, (Math.random() - 0.5) * 100],
            //   zoom: 10,
            //   duration: 1000,
            //   essential: true // this animation is considered essential with respect to prefers-reduced-motion
            // });
          } else {
            marker.m._popup.remove()

            // this.map.flyTo({
            //   center: [1.85, 46.85],
            //   zoom: 5.5,
            //   duration: 1000
            // })
          }
        })
      } else {
        // Adapt button style
        item.classList.remove(this.className)
      }

      // Scrollto the map
      gsap.to(window, { scrollTo: this.DOM.map })
    })
  }

  /**
   * Events for loading more references
   */
  
  loadMore() {
    const url = this.DOM.loadMoreButton.getAttribute('data-ajax-url')
    fetch(url)
    .then(res => res.json())
    .then(res => {
      this.DOM.postsContainer.innerHTML += res.html
      this.setMarkers(res.posts)
      this.setReferencesItems()
      this.DOM.loadMoreButton.setAttribute('data-ajax-url', res.pagination.url)
      if (!res.pagination.displayLoadMore) this.DOM.loadMoreButton.remove()
    }).catch(error => console.log(error))
  }

  /**
   * Destroy module
   */
  destroy() {
    this.markers && this.markers.length && this.markers.forEach(marker => {
      marker.p.remove()
      marker.m.remove()
    })

    this.map && this.map.remove()

    this.DOM.referencesItems && this.DOM.referencesItems.length && this.DOM.referencesItems.forEach(item => item.removeEventListener('click', this.displayPopup))

    this.total = 0

    // Modal Events
    this.DOM.openFiltersButton.removeEventListener('click', this.openModal)
    this.DOM.resetButton.removeEventListener('click', this.resetForm)
    this.DOM.sendButton.removeEventListener('click', this.sendForm)
    this.DOM.close.removeEventListener('click', this.closeModal)
    this.DOM.inputs.forEach(input => input.removeEventListener('change', this.updateTotal))
    this.DOM.navTabInputs.forEach(input => input.removeEventListener('change', this.updateTab))
    this.DOM.checkboxesPrimary.forEach(checkbox => checkbox.removeEventListener('change', this.updateFilteredItems))

    // Form
    this.formModule && this.formModule.destroy()
  }
}
