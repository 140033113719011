import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class Accordions {
  constructor(container) {
    if (!container) return

    this.DOM = {  container },
    this.DOM.formRadios = this.DOM.container.querySelectorAll('form input')
    this.DOM.accordions = this.DOM.container.querySelectorAll('.Accordion')
    this.DOM.accordionsButtons = this.DOM.container.querySelectorAll('.Accordion .head')

    this.setTabs()
    this.setAccordions()
  }

  setTabs() {
    if (!this.DOM.formRadios && !this.DOM.formRadios.length) return

    this.showTab = this.showTab.bind(this)

    this.DOM.formRadios.forEach(radio => radio.addEventListener('change', this.showTab))
  }

  showTab(e) {
    e && e.preventDefault()

    const { 
      container = null,
      accordions = null 
    } = this.DOM

    const activeTabValue = e.currentTarget.value

    accordions.forEach(accordion => {
      // We close all the accordions
      accordion.classList.remove('--active')
      
      accordion.classList.add('--transition')

      setTimeout(() => { 
        // If the user selects the All tab, then we display all the accordions
        if (activeTabValue === 'all') {
          accordion.classList.remove('--transition')
          accordion.classList.remove('--hide')

          return
        }

        // Only display the accordions that correspond to the value of the tab
        if (!accordion.classList.contains(`--${activeTabValue}`)) {
          accordion.classList.add('--hide')
        } else {
          accordion.classList.remove('--hide')
        }

        accordion.classList.remove('--transition')
      }, 550)
    })
  }

  setAccordions() {
    if (!this.DOM.accordionsButtons && !this.DOM.accordionsButtons.length) return

    this.toggle = this.toggle.bind(this)

    this.DOM.accordionsButtons.forEach(button => button.addEventListener('click', this.toggle))
  }

  toggle(e) {
    e && e.preventDefault()

    const currentAccordion = e.currentTarget.parentNode
    const content = currentAccordion.querySelector('.content')

    currentAccordion.classList.toggle('--active')

    content.style.maxHeight ?
      content.style.maxHeight = null :
      content.style.maxHeight = `${content.scrollHeight}px`
  }

  destroy() {
    this.DOM.formTabsRadios && this.DOM.formTabsRadios.length && this.DOM.formTabsRadios.forEach(radio => radio.addEventListener('change', this.showTab))
    this.DOM.accordionsButtons && this.DOM.accordionsButtons.length && this.DOM.accordionsButtons.forEach(button => button.removeEventListener('click', this.toggle))
  }
}
