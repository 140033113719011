import { domStorage, viewStorage } from '../_globals'
import Form from './form'

export default class Search {
  constructor() {
    if (!domStorage.search) return

    this.DOM = {
      toggleButton: domStorage.search.querySelector(':scope > button'),
      form: domStorage.search.querySelector('.Form'),
      input: domStorage.search.querySelector('input')
    }

    if (!this.DOM.toggleButton && !this.DOM.form) return

    // Init the form module
    // this.formModule = new Form(this.DOM.form)

    // Params
    this.formModule = null
    this.state = false

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.closeOutside = this.closeOutside.bind(this)

    this.DOM.toggleButton.addEventListener('click', () => {
      this.state = !this.state

      console.log('state', this.state)

      this.state ? this.open() : this.close()
    })
  }

  open(e) {
    e && e.preventDefault()

    // Focus on the input
    this.DOM.input.focus()

    // Update body className
    domStorage.body.classList.add('--show-search')

    // Stop smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // Add event on document for click outside the form
    setTimeout(() => document.addEventListener('click', this.closeOutside), 50)
  }

  close(e) {
    e && e.preventDefault()

    // Update body className
    domStorage.body.classList.remove('--show-search')

    // Update the state
    this.state = false

    // Blur on the input
    this.DOM.input.blur()

    // Reset Form
    this.DOM.form.reset()

    // Stop smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Remove event on document
    document.removeEventListener('click', this.closeOutside)
  }

  closeOutside(e) {
    e && e.stopPropagation()

    if (this.DOM.form.contains(e.target)) return

    this.close()
  }

  // sendForm(e) {
  //   e && e.preventDefault()

  //   alert('Send datas!')
  // }

  // destroy() {
  //   this.formModule && this.formModule.destroy()
  // }
}
