import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import Form from '../modules/form'

export default class SingleJob extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      form: viewStorage.current.querySelector('.Form')
    }

    this.init()
  }

  init() {
    const {
      form = null
    } = this.DOM

    // if (form) this.formModule = new Form(form)
  }

  onLeaveCompleted() {
    this.formModule && this.formModule.destroy()
  }
}
