import { domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

/**
 * @param {HTMLElement} - Name of the nav. element
 * @param {Object} - Sections we want to figure in the nav
 */
export default class FixedNavigation {
  constructor(el, sections = []) {
    if (!el) return

    this.DOM = { el }
    this.DOM.links = this.DOM.el.querySelectorAll('a')
    this.DOM.sections = sections

    // No section = remove fixed navigation from the DOM
    if (!this.DOM.sections && !this.DOM.sections.length) return this.DOM.el.remove()

    // Params
    this.tweens = []
    this.scrollTriggers = []

    // Events
    this.anchor = this.anchor.bind(this)

    // Anchors
    this.DOM.links && this.DOM.links.length && this.DOM.links.forEach(link => link.addEventListener('click', this.anchor))

    // ScrollTriggers
    this.DOM.sections.forEach((section, index) => {
      const related_link = this.DOM.links[index]

      const st = ScrollTrigger.create({
        trigger: section,
        start: 'top 80%',
        end: 'bottom 80%',
        onEnter: () => {
          // Highlight current link
          related_link.classList.add('--active')

          // Show the nav
          index === 0 && this.DOM.el.classList.add('--active')
        },
        onLeave: () => {
          // Highlight current link
          related_link.classList.remove('--active')

          // Hide the nav
          index === this.DOM.sections.length - 1 && this.DOM.el.classList.remove('--active')
        },
        onEnterBack: () => {
          // Highlight current link
          related_link.classList.add('--active')

          // Hide the nav
          index === this.DOM.sections.length - 1 && this.DOM.el.classList.add('--active')
        },
        onLeaveBack: () => {
          // Highlight current link
          related_link.classList.remove('--active')

          // Hide the nav
          index === 0 && this.DOM.el.classList.remove('--active')
        },
      })

      this.scrollTriggers.push(st)
    }) 
  }

  anchor(e) {
    e && e.preventDefault()

    const hash = e.currentTarget.getAttribute('href')
    const section = viewStorage.current.querySelector(hash)

    if (!section) return

    const tween = gsap.to(window, { 
      scrollTo: section, 
      ease: 'power2.inOut', 
      duration: 1,
      onStart: () => {
        domStorage.body.classList.add('--oh')
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()
      },
      onComplete: () => {
        domStorage.body.classList.remove('--oh')
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()
      }
    })

    this.tweens.push(tween)
  }

  destroy() {
    this.tweens && this.tweens.length && this.tweens.forEach(tween => tween.kill())
    this.scrollTriggers && this.scrollTriggers.length && this.scrollTriggers.forEach(st => st.kill())
  }
}
