import { domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import Swiper from 'swiper'

export default class NavSubmenus{
  constructor() {
    if (!domStorage.header) return

    this.DOM = {
      submenusItems: [...domStorage.header.querySelectorAll('li.--submenu')],
      submenusButtons: [...domStorage.header.querySelectorAll('li.--submenu .Link')],
      submenus: [...domStorage.header.querySelectorAll('.NavSubmenus')],
      backButtons: [...domStorage.header.querySelectorAll('.BackButton')],
      furnituresSubmenu: domStorage.header.querySelector('.NavSubmenu[data-furnitures]'),
      servicesSubmenu: domStorage.header.querySelector('.NavSubmenu[data-services]'),
      servicesSwiper: domStorage.header.querySelector('.swiper')
    }

    if (!this.DOM.submenusButtons && !this.DOM.submenusButtons.length) return

    // Params
    this.className = '--active'
    this.currentSubmenu = null
    this.state = false

    // Events
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.closeOutside = this.closeOutside.bind(this)

    this.DOM.submenusButtons.forEach(button => button.addEventListener('click', this.open))
    this.DOM.backButtons.forEach(button => button.addEventListener('click', this.close))

    // Service Submenu swiper
    this.servicesSwiperModule = null
    this.setServicesSubmenuSwiper()
  }

  setServicesSubmenuSwiper() {
    if (!this.DOM.servicesSwiper) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = { 
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }  

    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.servicesSwiperModule = new Swiper(this.DOM.servicesSwiper, { slidesPerView: 'auto', spaceBetween: 15, centerInsufficientSlides: true })
      } else {
        if (this.servicesSwiperModule)  this.servicesSwiperModule.destroy(true, true)
      }
    })
  }

  open(e) {
    e && e.preventDefault()

    // Update current submenu
    this.currentSubmenu = e.currentTarget.parentNode

    // Update current submenu classNames
    this.DOM.submenusItems.filter(item => { item === this.currentSubmenu ? item.classList.toggle(this.className) : item.classList.remove(this.className) })

    // Update body className depending of current submenu
    this.currentSubmenu.classList.contains(this.className) ? domStorage.body.classList.add('--show-submenu') : domStorage.body.classList.remove('--show-submenu')

    // Stop smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    // Add event on document for click outside the currentSubmenu
    setTimeout(() => document.addEventListener('click', this.closeOutside), 50)
  }

  close(e) {
    e && e.preventDefault()

    if (!this.currentSubmenu) return

    // Update body className 
    domStorage.body.classList.remove('--show-submenu')

    // Remove className
    this.currentSubmenu.classList.remove(this.className)

    // Update current submenu
    this.currentSubmenu = null

    // Start smooth scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

    // Remove event on document
    document.removeEventListener('click', this.closeOutside)
  }

  closeOutside(e) {
    e && e.stopPropagation()

    if (!this.currentSubmenu || this.currentSubmenu.contains(e.target)) return

    this.close()
  }

  destroy() {
    this.servicesSwiperModule && this.servicesSwiperModule.destroy(true, true)
  }
}
