import { isDEVMODE, globalStorage, viewStorage, domStorage, seoOptimizer } from './_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { updateHeaderTheme } from './utils'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import { FormsManager } from '@wokine-agency/forms'

/**
 * Forms
 */

function initForms({DOM}){
  FormsManager.init({
    debug: true,
    DOM,
    forms: {
      contact: {},
      newsletter: {},
      application_form: {},
      technical_sheet: {},
    }
  });
}

export function onUploadFile(e) {
  const parent = e.currentTarget.parentNode.parentNode
  const res = parent.querySelector('.res')
  const resText = res.querySelector('.res-text')
  const removeButton = res.querySelector('button')

  res.classList.add('--active')
  resText.textContent = e.currentTarget.files[0].name

  removeButton.addEventListener('click', removeFile.bind(this))
}

export function removeFile(e) {
  e && e.preventDefault()

  const res = e.currentTarget.parentNode
  const resText = res.querySelector('.res-text')
  const input = res.parentNode.querySelector('input')

  resText.textContent = ''
  res.classList.remove('--active')
  input.value = ''
}

/**
 * Page : Contact / Devis
 */

function switchContactPageType ({ location }) {
  const navButtons = domStorage.header.querySelectorAll('.NavButtons .Btn')

  navButtons.forEach(button => {
    button.removeAttribute('data-taxi-ignore')
    button.removeAttribute('target')
    button.removeAttribute('rel')
  })

  if (!location || !location.includes('/contact')) return

  let h1 = viewStorage.current.querySelector('h1')
  let subjectField = FormsManager.getForms('contact')[0].getField('subject').getElementsAsArray()[0]
  navButtons.forEach(button => {
    button.setAttribute('data-taxi-ignore', 'true')
    button.setAttribute('target', '_blank')
    button.setAttribute('rel', 'noreferrer noopener')
  })

  if (location.includes('#devis')) {
    document.title = 'Demande de devis - Urbanéo'
    h1.textContent = 'Demandez un devis'
    subjectField.value = 'Obtenir un devis'
  } else {
    h1.textContent = h1.dataset.default
  }
}

/**
 * Events
 */

export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)
  viewStorage.inputFiles = viewStorage.current.querySelectorAll('input[type="file"]')
  viewStorage.inputFiles && viewStorage.inputFiles.length && viewStorage.inputFiles.forEach(input => input.addEventListener('change', onUploadFile.bind(this)))

  // Intro
  GlobalIntro()

  // Forms
  initForms({
    DOM: domStorage.body
  })

  // Contact / Devis
  switchContactPageType({ location: window.location.href })

  // Update header theme
  updateHeaderTheme(viewStorage.current)
}

export const onEnter = (to, trigger) => {
  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')
  seoOptimizer.optimize(to.page)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()

  // Forms
  initForms({
    DOM: viewStorage.current
  })

  // Contact / Devis
  switchContactPageType({ location: globalStorage.taxi.targetLocation.raw })

  viewStorage.inputFiles = viewStorage.current.querySelectorAll('input[type="file"]')
  viewStorage.inputFiles && viewStorage.inputFiles.length && viewStorage.inputFiles.forEach(input => input.addEventListener('change', onUploadFile.bind(this)))

  // Update header theme
  updateHeaderTheme(viewStorage.current)

}

export const onEnterCompleted = (to, from, trigger) => {
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }
}

export const onLeave = (from, trigger) => {
  // Remove Previous Page Effects
  const { hasInView, hasPrllx } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()

  // LoadingClasses
  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  globalStorage.menuOpen && globalStorage.closeMobileMenu()
  globalStorage.navSubmenus && globalStorage.navSubmenus.close()

  const { targetLocation } = globalStorage.taxi

  // Header Nav : Remove active links
  const navLinks = domStorage.menu.querySelectorAll('.Nav__link')
  navLinks && navLinks.length && navLinks.forEach(link => link.href === targetLocation.raw ? link.classList.add('--active') : link.classList.remove('--active'))
  viewStorage.inputFiles && viewStorage.inputFiles.length && viewStorage.inputFiles.forEach(input => input.removeEventListener('change', onUploadFile.bind(this)))
  FormsManager.destroy({
    DOM: viewStorage.current
  });
}
